import { useState } from "react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Grid, GridItem } from "@chakra-ui/react";

const CustomAccordion = ({ items,isHome }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return ( <Grid
    className="grid-wrapper Faq_wrapper"
    templateColumns={{ base: "repeat(1, 1fr)", md: isHome ? "repeat(1, 1fr)" : "repeat(2, 1fr)" }}
    gap={6}
  >
    {items.map((item, index) => (
      <GridItem key={index} className="faq-page-accordion">
        <Accordion allowToggle index={activeIndex === index ? [0] : []}>
          <AccordionItem
            sx={{
              borderTop: 'none',
            }}
            isExpanded={activeIndex === index}
          >
            <h2>
              <AccordionButton onClick={() => toggleAccordion(index)}>
                <Box as="span" flex="1" className="heading-color text-left">
                  {isHome ? index + 1 + '.' : ''} {item.question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {item.ans}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </GridItem>
    ))}
  </Grid>
  );
};

export default CustomAccordion;