import React, { useEffect } from "react";
import { Spinner, Flex } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Flex
      w="full"
      h="100vh"
      alignItems="center"
      justifyItems="center"
      justifyContent="center"
      sx={{position:'absolute'}}
    >
      <Spinner
        thickness="9px"
        speed="0.65s"
        emptyColor="#fff"
        color="gray.800"
        size="md"
        backgroundColor={"#fff"}
      />
    </Flex>
  );
};

export default Loader;
