import TestimonialSlider from "./TestimonialSlider";
import { Box } from "@chakra-ui/react";


const Testimonial = () => {
  return (
    <Box
      w={["100%", "100%"]}
      className="home-testimonial-wrap mb-80 relative">
      <Box className="container mx-auto">
        <Box className="testimonial-slider-wrapper">
          <h2 className="heading-color mb-30">What People Say</h2>
          <TestimonialSlider />
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonial;
