import NormalTitle from "@/utils/NormalTitle";
import fetcher from "@/utils/fetcher";
import { Box, Img } from "@chakra-ui/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import useSWR from "swr";

const TestimonialSlider = () => {
  const { data } = useSWR("/api/testimonials", fetcher);
  return (
    <Swiper
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      spaceBetween={30}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        576: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
      }}
      pagination={false}
      navigation={true}
      allowSlideNext={true}
      allowSlidePrev={true}
      modules={[Pagination, Autoplay, Navigation,]}
      className="testimonial_slider"
    >
      {data && data?.testimonials?.filter(r=>r.status)?.map((item, index) => (
        <SwiperSlide key={index} className="">
          <Box>
            <Box className="quote-img ">
              <Img src="/Qoute-img.png" alt="quote-img" />
            </Box>
            <NormalTitle Style="text-left">
              {item.testimonial}
            </NormalTitle>
          </Box>
          <h5 className="font-normal pt-2 flex justify-start items-center gap-1 text-[#333]">
            - {item.name}
          </h5>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TestimonialSlider;
