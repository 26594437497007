import React, { useState } from 'react';
import {
    Flex,
    Box,
} from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";
import { useRouter } from "next/navigation";
import NextLink from "next/link";
export const Banner = () => {
    // the query user will search
    const [search,setSearch] = useState("");

    const router = useRouter()
    // get the query pass it with the router
    const handleNavigate = (e)=>{
        e.preventDefault();
        const query = {
          search: search,
        };
        router.push({
          pathname: "/reviewers/listing",
          query: query,
        });
    }

    return (
        <Box
            w={["100%", "100%"]}
            className="home-main-banner-wrap mb-80"
        >
            <Box className='container mx-auto'>
                <Flex
                    className="home-main-banner-content grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-2 gap-4"
                    w={["100%", "100%", "100%"]}
                >
                    <Box w={"50%"} className='home-banner-des'>
                        <h1 className='mb-50 heading-color'>
                            Unlock Your <br /> Professional Potential <br /> with SkilSoar
                        </h1>
                        <NextLink href='/reviewers/listing' className='btn dark-blue-btn'>Get Started</NextLink>

                        <Box className='schedule-session-wrap'>
                            <h3 className='heading-color'>Schedule Your Session</h3>


                            <form className="max-w-md">
                                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                <Box className="relative">
                                    <input type="search" id="default-search" className="block w-full text-sm rounded-full dark:placeholder-gray-400 dark:text-white" placeholder="Search Industry | Job Title | Skill" 
                                    onChange={(e)=>setSearch(e.target.value)}
                                    />
                                    <button type="submit" className="text-white hover:bg-blue-800 font-medium rounded-full text-sm dark:hover:bg-blue-700 dark-blue-btn" onClick={(e)=>handleNavigate(e)}
                                    disabled={search.trim("").length <= 1}
                                    >
                                        <svg className="w-4 h-4 text-white-500 flex mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </button>
                                </Box>
                            </form>

                        </Box>
                    </Box>
                    <Box w={["100%", "100%", "50%"]} className='home-banner-img'>
                        <Image src="/home-banner-img.png" width={"100%"} alt="" />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};
