const NormalTitle = ({ children, Style = "" }) => {
  return (
    <p
      className={`font-light text-sm sm:text-base flex justify-start items-center gap-1 ${Style}`}
    >
      {children}
    </p>
  );
};

export default NormalTitle;
