const indexContent = {
  en: {
    title: `36 views`,
    introTitle: `Online Portfolio Photo Review`,
    content: {
      aboutText: `<p style=' padding: 2% 0 '>Trade shows, conventions, industry workshops and seminars were primary venues where photographers interested in having their work critiqued could gain access to qualified creative professionals. It was costly, tedious, intermittent and very limited in terms of matching between reviewer’s background and photographer’s goals. As a result, portfolio review occurred too rarely in the creative process, and if any, quite often too late to reorient the project.</p>
        <p style=' padding: 2% 0 '>36 Views’ vision is to trivialize and democratize the portfolio review, to make it as a routine, an ordinary and regular milestone in the art creation process.</p>
        <p style=' padding: 2% 0 '>36 Views eliminates travels and print cost, entrance and accommodations fee, … We make the process of portfolio review available 365 days a year and tailormade thanks to a fine screening of experts.</p>
        <p style=' padding: 2% 0 '>It was important to us to replicate the satisfaction of the in-person photography review when we developed our services, as well as selecting people able to make you grow as an artist, through warm and appropriate terms.</p>
        <p style=' padding: 2% 0 '>Lastly, in order to improve the quality of our review services e.g. relevance of comments, constructive and didactic comments, tact, …etc., reviews are randomly analyzed by our internal quality service within a time span of 3 months from the review date. In case you wish to express comments, bad or good, on our services or your reviewer, please help us (and him), by emailing on <a href="mailto:quality@36views.photo">quality@36views.photo</a></p>`,
      introText: `Get your portfolio photo reviewed by key people from image industry through real-time video meetings.`,
      titleReviewer: `Our top reviewers`,
      textReviewer: `To best suite the purpose you intend your photographic work, 36 Views presents its meticulous selection of professionals, each with their relevant background in different art industry areas.`,
      readMoreReviewer: `Tips to select your reviewer`,
      textModalReviewer: `<b>Request an award-winning photographer</b> when your questions are related to technical matters, aesthetic aspects, or narrative improvement. Please ensure that his/her artistic tastes are close to yours.<p style='padding: 20px 0'> <b>Request experts from image industry</b> such as editors, gallery owners, magazines art director, curators, exhibition commissioners, buyer’s agent…, when your questions are related to the coherence of a series, its narrative thread, the approach angle of a theme or the market you aim, ...</p>
      <p style='padding-top: 16px'> Take the time to carefully select your reviewer to best match his background, your kind of photography and the purpose you intend your work (publication, exhibition, …). Subscribe to one of his/her services**, upload your pictures and clearly describe your project. Then start to chat with him/her in order to appoint the videoconference. It is easy and human.</p>
      <p style='padding-top: 16px'> Do not neglect human aspect and to rely to irrational feeling as well as the esteem for your reviewer’s work and vision. Finding a mentor is a thing as rare as precious, for you as for him. 36Views might well be the place of your first encounter and it is one of the reasons 36Views has been made for … </p>`,
      titleAbout: `Vision & philosophy`,
    },
    services: {
      introService: `Choose your reviewer, pick-up the kind of review you wish, upload your project and then chat with him/her to appoint your videoconference.`,
      readMore: `Read more`,
      titleService1: `Our review services`,
      titleService2: `A personal space`,
      titleService3: `Absolute confidentiality and full respect of IP rights.`,
      textService1: `classNameical review, editing or sequencing services, all are provided through a live video conversation with your reviewer, around your pictures showing on the shared screen.<p style='padding: 20px 0'> A more democratic (but one-sided) services can always be provided through video recording, available within 8 work days in your login area.</p>`,
      textService2: `36Views has developed a technology in order to record the whole review i.e. voices as well as actions showing on screen during the review. <p style='padding: 20px 0'>All your reviews are saved for 2 years on your login area.</p>`,
      textService3: `Get your video conference on 36Views in a fully secure room. While subscribing the service you can opt for confidentiality and even go further in the anonymous by disconnecting your webcam.<p style='padding: 20px 0'>36Views shares absolutely no information no documents no images with third parties.</p>`,
      modalService1: `<b>classNameical Reviewing service</b> : It is a live review through our interactive workspace aiming to obtain constructive critiques through a caring dialogue with your expert about your work/project largely described while subscribing.
      <p style='padding-top: 16px'><b>Editing service</b> : Reviewer encompass photographs as a specific project/reportage. The purpose is to end the review with an agreed-upon limited collection regrouping the most evocative pictures and avoiding redundancy or down time.</p>
      <p style='padding-top: 16px'><b>Sequencing service</b> : Reviewer helps to sequence the final editing in the most meaningful order to provide the most intelligible and relevant story.</p>`,
      modalService2: `Your projects and the records of your reviews are saved for 2 years on your login area to allow you to listen it over and over again. You can also download this full audio/video file on your PC and delete it from your personal space, the planet will be getting better.
      <p style='padding-top: 16px'>Your login area is also the place you will find the one-sided records of the more democratic review services you would have subscribed.</p>`,
      modalService3: `On the day of the review, log in and get your video conference on 36Views in a fully secure room. While subscribing the service you can opt for confidentiality (checkbox) and even go further in the anonymous by disconnecting your webcam before starting the review.
      <p style='padding-top: 16px'>Your reviewer will then know nothing about you but your nickname, your pictures and information you provided about you and your project in the information sheet. </p>
      <p style='padding-top: 16px'>36Views shares absolutely no information with third parties, beside your selected reviewer who is strictly bound by a NDA and a confidentiality undertaking. On the other hand, 36Views’ reputation is based on a strict confidentiality and protection of your identity and your intellectual property rights. Believe us, as photographers these points are not taken lightly.</p>`,
    },
  },
  fr: {
    title: `36 vues`,
    introTitle: `Service en ligne de relectures professionnelles de portfolios photos dédié aux photographes d’art, amateurs ou professionnels.`,
    content: {
      introText: `<p style='padding: 20px 0'>36Views, ce sont des services de relecture classNameique, d’Editing ou de Sequencing, ou même des conseils sur les pratiques de secteur ou sur votre carrière artistique, <b>dans des termes toujours constructifs et adéquats</b>, dans l’intention d’améliorer la qualité narrative de vos projets en sublimant des histoires que d’autres voudront publier, exposer ou acquérir. </p>`,
      titleReviewer: `Nos lecteurs de portfolio`,
      textReviewer: `36Views présente une sélection méticuleuse et réduite de professionnels, chacun avec son expérience propre détaillée, tous issus de métiers spécifiques à l’industrie de l’art et de l’image.`,
      readMoreReviewer: `Comment bien sélectionner son lecteur`,
      textModalReviewer: `<b>Choisissez un profil de photographes</b> pour des questions techniques ou esthétiques, ou pour renforcer  votre propos. Assurez-vous que son genre photographique (rue, abstrait, portrait,..) et ses goûts artistiques correspondent aux vôtres.
        <p style='padding-top: 16px'><b>Choisissez un expert</b> parmi nos éditeurs, galeristes, directeurs artistiques de magazine, curateurs ou commissaires d’exposition, pour améliorer la cohérence d’une série, sa force narrative ou l’angle d’approche de votre thème. Faites correspondre son secteur professionnel avec la destination de votre projet (édition, exposition, vente directe).</p>
        <p style='padding-top: 16px'> Souscrivez à un de ses services, uploadez vos photographies, décrivez amplement votre projet et chatter directement avec lui pour convenir de la date du review. 36Views est facile et humain.</p>
        <p style='padding-top: 16px'>Fiez-vous aussi à votre instinct. Trouvez un mentor est une chose rare et précieuse, pour elle/lui comme pour vous. 36Views pourrait bien être le lieu de cette rencontre et a été pensé dans cette optique. </p>`,
      titleAbout: `Vision et philosophie`,
      aboutText: `<p style=' padding: 2% 0 '>Les salons professionnels, les ateliers et les séminaires étaient les principaux lieux des reviews professionnels. C’était coûteux, fastidieux, intermittent et très limité dans le choix du Reviewer.</p>
        <p style=' padding: 2% 0 '>Les reviews intervenaient par conséquent trop rarement dans le processus créatif, et le cas échéant, souvent trop tard pour réorienter le projet.</p>
        <p style=' padding: 2% 0 '>La vision de 36 Views est de banaliser et de démocratiser la relecture de portfolio photo, d'en faire une routine, un jalon ordinaire et régulier tout au long du processus créatif.</p>
        <p style=' padding: 2% 0 '>36Views élimine les frais de déplacement et d'impression, les frais d'entrée et d'hébergement de la relecture classNameique … Nous rendons le Review disponible 365 jours par an et sur mesure grâce à une sélection fine d'experts.</p>
        <p style=' padding: 2% 0 '>Il était important pour nous de reproduire l’entretien, l’échange humain entre le Reviewer et le photographe lorsque nous avons développé nos services, ainsi que de sélectionner des personnes capables de vous faire évoluer en tant qu'artiste, à travers des termes encourageants, constructifs et appropriés.</p>
        <p style=' padding: 2% 0 '>Enfin, pour améliorer la qualité des commentaires afin qu’ils soient toujours plus constructifs et didactiques, et surtout donnés avec tact, les Reviews des 3 derniers mois sont analysés aléatoirement par notre service qualité interne. Au cas où vous souhaiteriez exprimer spontanément des commentaires, bons ou mauvais, sur nos services ou votre reviewer, vous nous aiderez (et l’aiderez lui), en nous adressant un e-mail à <a href="mailto:quality@36views.photo">quality@36views.photo</a></p>`,
    },
    services: {
      introService: `Choisissez votre relecteur, le service de Review souhaité, téléchargez votre projet et chattez directement avec lui/elle pour convenir de la date de votre vidéoconférence.`,
      readMore: `En savoir plus`,
      titleService1: `Nos services de relecture`,
      titleService2: `Un espace personnel`,
      titleService3: `Respect absolu de la confidentialité et des droits d’auteurs.`,
      textService1: `La Relecture classNameique, l’Editing ou le service de Sequencing sont dispensés via une vidéoconférence avec votre Reviewer, autour de vos photos affichées sur l’écran partagé.<p style='padding: 20px 0'>Tous nos services sont disponibles en mode économique (mais unilatéral), qui consiste en une vidéo enregistrée par votre relecteur, disponible dans votre espace personnel endéans les 8 jours.</p>`,
      textService2: `A des fins didactiques, 36Views a développé une technologie permettant d’enregistrer l’intégralité de vos Reviews dans votre espace personnel, c’est-à-dire tant les voix que les actions opérées sur l’écran partagé durant votre Review.<p style='padding: 20px 0'>Ceci vous permet d’y revenir quand vous le souhaitez. Tous vos Reviews, ainsi que vos galeries par projet y sont conservés 2 ans.</p>`,
      textService3: `Vos Reviews se déroulent dans un espace sécurisé sur notre plateforme. Pour chaque nouveau projet, vous pouvez opter pour la confidentialité de votre identité ou allez plus loin dans l’anonymat, et déconnecter votre webcam avant le Review.<p style='padding: 20px 0'>36Views ne partage absolument aucune informations personnelles avec des tierces parties.</p>`,
      modalService1: `<b>Service de Relecture classNameique</b> : Vous discutez avec votre Reviewer dans un espace de travail sécurisé afin obtenir une critique constructive au cours d’une discussion bienveillante autour de votre travail affiché sur l’écran partagé. 
      <p style='padding: 16px 0'><b>Editing Service</b> : Devoir choisir parmi ses œuvres peut se révéler très pénible. Votre Reviewer vous accompagne dans cette étape essentielle de sélection à une nombre de photos que vous avez déterminé. Il considère votre travail comme un ensemble traitant d’un sujet particulier et vous évitera les redondances et les évocations un peu plus faibles.</p>
      <p style='padding: 8px 0'><b>Sequencing Service</b> : S’effectue après le travail d’éditing. Votre Reviewer vous aidera à ordonner vos photographies dans un sens précis, restituant ainsi une histoire bien rythmée qui soit la plus intelligible et pertinente possible.</p>`,
      modalService2: `Vous pouvez toujours télécharger et sauvegarder vos Reviews et vos projets sur votre PC. La planet ne s’en portera que mieux.`,
      modalService3: `A l’heure convenue de votre videoconference, loggez-vous sur votre espace personnel et commencez votre Review avec votre Reviewer dans un espace confidentiel. Lorsque vous souscrivez à une videoconference chez 36Views, vous pouvez opter pour la confidentialité de votre identité (checkbox) et même aller plus loin dans l’anonymat, en déconnectant votre webcam avant le démarrage de votre Review.
      <p style='padding: 16px 0'>Hormis votre nickname, votre Reviewer ignorera alors tout de votre identité, si ce n’est ce que vous direz de vous au travers vos photographies et de votre document d’information sur votre projet.</p>
      <p style='padding: 8px 0'>36Views ne partage absolument aucune information avec des parties tierces, en dehors de votre Reviewer qui est strictement tenus à un engagement de non Boxulgation et de confidentialité. La réputation de 36Views est précisément basée sur le respect stricte de cette confidentialité et sur la protection de vos droits d’auteurs. Croyez-nous, en tant que photographes nous-même, ce n’est pas un aspect que nous prenons à la légère.</p>`,
    },
  },
};

export default indexContent;
