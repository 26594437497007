import Head from "next/head";
import React, { useState, useEffect } from "react";
import indexContent from "@/translations/indexContent";
import {
  Flex,
  Box,
} from "@chakra-ui/react";
import "keen-slider/keen-slider.min.css";
import { useAuth } from "@/lib/auth";
import { Banner } from "@/components/Home/banner";
import { HowSkilsourWorks } from "@/components/Home/how-skilsour-works";
import {TopFeaturedMentor} from "@/components/Home/top-featured-mentor";
import {TopIndustries} from "@/components/Home/top-industry";
import {HomeFaq} from "@/components/Home/faq";
import Testimonial from "@/components/Home/Testimonial/Testimonial";
const Home = () => {
  const { introTitle } = indexContent["en"];
  const { user } = useAuth();
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("content-type", "application/json");
  myHeaders.append("token", "undefined");
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  useEffect(() => {
    if (user?.uid) {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("content-type", "application/json");
      myHeaders.append("token", "undefined");
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(`/api/user/${user?.uid}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.user?.role == "reviewee") {

          }
        })
        .catch((error) => console.error(error));
    }

  }, [user?.uid]);

  return (<>
      <Box className="main-box">
        <Flex
          as="main"
          direction="column"
          align="center"
          justify="center"
          px={{ base: "15px", md: "15px", xl: "0" }}
        >
          <Head>
            <title>Skilsoar</title>
          </Head>
          <Banner/>
          <HowSkilsourWorks/>
          <TopFeaturedMentor/>
          <TopIndustries/>
          <HomeFaq/>
          <Testimonial/>
        </Flex>
      </Box>
    </>
  );
};

export default Home;
