import React,{useEffect,useState} from "react";
import firebase from "firebase";
import ReviewersCard from "./card";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ReviewersGrid = ({ reviewers, isSlider }) => {
  const firestore = firebase.firestore();
  const db = firestore;
  const [sessions,setSessions]=useState([]);
  useEffect(()=>{
    const unsubscribe = db.collection(`sessions`)
      .onSnapshot(async(snapshot) => {
        let cusers=[];
        await snapshot.docs.forEach(async(doc) => {
          let docs={
            ...doc.data(),
            id: doc.id,
          };
          if(docs && (docs?.status==='completed')){
            cusers.push({...docs});
            setSessions(cusers);
          }
        });
      
      }, (error) => {
        console.error('Error fetching sessions:', error);
      });
    return () => unsubscribe(); 
  }, []);
 
  return (
    <>
      {isSlider?<Swiper
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          spaceBetween={0}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
        pagination={{ clickable: true }}
        modules={[Pagination, Autoplay, Navigation]}
          className="testimonial_slider"
        >
          {reviewers?.map((reviewer, idx) =><SwiperSlide key={idx}>
            <ReviewersCard idx={idx} sessions={sessions} reviewer={reviewer} isSlider={isSlider}/>
          </SwiperSlide>)}
        </Swiper>
        :<>
         {reviewers?.map((reviewer, idx) =><ReviewersCard idx={idx} sessions={sessions} reviewer={reviewer} isSlider={isSlider}/>)}
        </>}
    </>
  );
};

export default ReviewersGrid;
