import React, { useEffect, useState } from 'react';
import ReviewersGrid from "@/components/reviewer/ReviewersGrid";
import {
  Heading,
  Flex,
  Box,
  Button
} from "@chakra-ui/react";
import useSWR from "swr";
import fetcher from "@/utils/fetcher";
import NextLink from "next/link";
import { useAuth } from '@/lib/auth';
export const TopFeaturedMentor = () => {
  const [mentors, setMentors] = useState([]);
  const {data} = useSWR("/api/users", fetcher);
  const {user} = useAuth();
  useEffect(()=>{
    if(data){
      const filteredMentors = data?.users?.filter((i, _)=>i?.role !== "owner" && (i.id!==user?.uid) && i?.is_activated && i?.is_applied && i?.is_approved);
      filteredMentors && setMentors([...filteredMentors]);
    }
  }, [data])

  const [reviewers, setReviewers] = useState([]);
  return (mentors?.length>0 && <Box w={["100%", "100%"]} className="mentor-cards-wrap mb-80">
      <Box className="container mx-auto">
        <h2 className="heading-color">Top Featured Mentor</h2>
        <Box w={["100%", "100%", "100%", "100%"]}>
          <Flex className="review-card-wrapper mt-25">
            <ReviewersGrid reviewers={mentors} />
          </Flex>
          <NextLink href="/reviewers/listing" className="flex">
            <Button variant="solid" className="btn dark-blue-btn mt-25 mx-auto">
              View All
            </Button>
          </NextLink>
        </Box>
      </Box>
    </Box>
  );
};
