import React from 'react';
import {
    Flex,
    Box,
    Button
} from "@chakra-ui/react";
import NextLink from "next/link";
import Link from 'next/link';
import useSWR from "swr";
import fetcher from "@/utils/fetcher";
import { useRouter } from 'next/router';
export const TopIndustries = () => {
    const router = useRouter();
    const { data } = useSWR("/api/AllServices/industry", fetcher);
    return (
        <Box
            w={["100%", "100%"]}
            className="top-industry-wrap mb-80"
        >
            <Box className='container mx-auto'>
                <Flex
                    w="100%"
                    justifyContent="space-between"
                    className='mb-30'
                    alignItems={"center"}
                >
                    <h2 className='text-white' textalign="left">
                        Top Industry
                    </h2>
                    <NextLink href="/reviewers/listing">
                        <Button variant="solid" className="btn white-btn">
                            View All
                        </Button>
                    </NextLink>
                </Flex>
                <Box w={["100%", "100%", "100%", "100%"]}>
                    <Flex
                        w="100%"
                        className='top-industry-col-wrap'
                        gap={3}
                    >
                        {data?.industries?.map((card,i) => (
                            <Box
                                key={i}
                                as={card?.status?"a":"div"}
                                flex="1"
                                className='top-industry-col relative'
                                onClick={()=>card?.status && router.push(`/reviewers/listing?industry=${card?.name}`)}
                            >
                                {card?.name}
                                {!card?.status && <p className='text-red-600 absolute w-full coming-soon-text bottom-[-10px] left-[50%] translate-x-[-50%] z-40'>Coming soon...</p>}
                            </Box>
                        ))}
                    </Flex>
                </Box>
            </Box>
        </Box>
    );
};
