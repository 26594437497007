import React from 'react';
import {
    Flex,
    Box,
} from "@chakra-ui/react";
import { BsCalendar2Date, BsSearch } from 'react-icons/bs';
import { CiBookmarkCheck } from "react-icons/ci";
export const HowSkilsourWorks = () => {

    const cards = [
        { name: 'Find your Mentor', number: '01', description: 'Search by Industry, Job Title or Skill and select the perfect mentor.', icon:<BsSearch className='h-7 w-7 heading-color mb-2' />
        },
        { name: 'Schedule your Session', number: '02', description: 'Simply reserve a time slot on their calendar.', icon: <BsCalendar2Date className='h-7 w-7 heading-color mb-2' /> },

        { name: 'Elevate', number: '03', description: 'Let your Skills Soar',
             icon: <CiBookmarkCheck className='h-9 w-9 heading-color' /> }
    ]
    return (
        <Box
            w={["100%", "100%"]}
            className='how-skillSoar-Works-wrap mb-80'>
            <Box className='container mx-auto'>
                <h2 className='heading-color text-center mb-80'>
                    How SkillSoar Works
                </h2>
                <Box w={["100%", "100%", "100%", "100%"]}>
                    <Flex
                        w="100%"
                        justifyContent="space-between"
                        direction={["column", "row"]}
                        gap={4}
                    >
                        {cards.map((card) => (
                            <Box
                                key={card.number}
                                flex="1"
                                className='skillSoar-works-col'
                            >
                                 <Box className="card-icon">{card.icon}</Box>
                                <Box className='skillSoar-works-num'>
                                    <h1>{card.number}</h1>
                                </Box>
                                <Box className='skillSoar-works-name'>
                                    <h4>{card.name}</h4>
                                </Box>
                                <Box className='skillSoar-works-des'>
                                    <p className='text-gray'>{card.description}</p>
                                </Box>
                            </Box>
                        ))}
                    </Flex>
                </Box>
            </Box>
        </Box>
    );
};
