import NextImage from "next/image";
import { chakra } from "@chakra-ui/react";

const Img = chakra(NextImage, {
  shouldForwardProp: (prop) => {
    return [
      "width",
      "height",
      "src",
      "quality",
      "priority",
      "layout",
      "loading",
      "placeholder",
      "objectFit",
      "alt",
    ].includes(prop);
  },
});

export const Image = (props) => {
  return <Img {...props} />;
};
