import React from "react";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Stack,
  ButtonGroup,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { Image } from "@/components/Image";
import BookingStepsModal from "../modal/BookingModals";
import { FaStar } from "react-icons/fa";
import {getUserRate} from "@/lib/db";
const ReviewersCard = ({ idx,sessions,reviewer,isSlider}) => {
  return (<Card
              key={idx}
              className={"review-card "}
              style={isSlider?{ margin:"10px" }:{}}
            >
              <CardBody className="review-card-body">
                <Box className="review-card-img-wrapper">
                  <Image
                    src={reviewer?.profile_pic}
                    priority="true"
                    loading="eager"
                    width={100}
                    height={100}
                    objectFit="cover"
                    overflow="hidden"
                  />
                </Box>
                <Stack spacing="3" className="review-card-body-content">
                  <Box>
                    <Box
                      className="text-center pb-2"
                      borderBottom={"1px solid #DDDDDD"}
                    >
                      <h5 className="heading-color capitalize">{`${reviewer?.first_name} ${reviewer?.last_name}`}</h5>
                      <h6 className="light-black">
                        {reviewer.awards && reviewer.awards}
                      </h6>
                      <p className="!flex items-center justify-center gap-2 !mb-0">
                        <span className="flex  text-yellow font-medium gap-1">
                          <FaStar className="h-4 w-4" /> {getUserRate(sessions?.filter(r=>((r?.mentor===reviewer?.uid) || (r?.mentor===reviewer?.id)) && r?.rating)) || 0}
                        </span>
                        <p className="!mb-0">({sessions?.filter(r=>(r?.mentor===reviewer?.uid) || (r?.mentor===reviewer?.id) && r?.rating)?.length || 0} Sessions Completed)</p>
                      </p>
                    </Box>
                  </Box>
                  <Box className="text-left">
                    <p className="light-black mb-0">
                      <b className="heading-color">Skills:</b> {reviewer?.skills?.slice(0, 2).map((s,i)=>s?.name+((i<reviewer?.skills?.slice(0, 2)?.length-1)?', ':''))}
                    </p>
                    <p className="light-black mb-0">
                      <b className="heading-color">Industry:</b> {reviewer?.industry?.slice(0,2).map((s,i)=>s?.name+((i<reviewer?.industry?.slice(0,2)?.length-1)?', ':''))}
                    </p>
                    <h6 className="heading-color font-bold mt-[7px]">
                      Services Offered
                    </h6>
                    <ul className="ps-0">
                      {reviewer?.services?.length > 0 &&
                        reviewer?.services?.map((serv, ind) => (
                          <>
                            <li className="gray-color mb-0" key={ind}>
                              {serv.name}:
                              <span className="text-black font-medium ml-1">
                                 ${serv?.rate}/hr
                              </span>
                            </li>
                          </>
                        ))}
                    </ul>
                  </Box>
                </Stack>
              </CardBody>

              <CardFooter>
                <ButtonGroup>
                  <NextLink
                    href={`/reviewers/${reviewer.id}`}
                    className="blue-outline-btn"
                  >
                    View Profile
                  </NextLink>
                  <BookingStepsModal
                    project={reviewer}
                  />
                </ButtonGroup>
              </CardFooter>
            </Card>
  );
};

export default ReviewersCard;
