import React, { useEffect, useState } from 'react';
import {
    Box,
    Img
} from "@chakra-ui/react";
import NextLink from "next/link";
import CustomAccordion from "@/utils/CustomAccordion";
import fetcher from "@/utils/fetcher";
import useSWR from "swr";
export const HomeFaq = () => {
    const { data } = useSWR("/api/faq", fetcher);
    const [activeFaqs, setactiveFaqs] = useState([]);
    useEffect(()=>{
        if(data){
            setactiveFaqs(data?.faqs?.filter((d)=>d?.status === true))
        }
    }, [data])
    return (
        <Box
            w={["100%", "100%"]}
            className="home-faq-wrap mb-80 relative"
        >
            <Box className='container mx-auto'>
                <h2 className='heading-color text-center mb-30'>Frequently Asked Questions</h2>
                <Box className='img-with-accordion-wrap'>
                    <Box className='home-faq-img-wrap'>
                        <Img src='/home-faq-img.png' alt='faq-img' />
                    </Box>
                    <Box className='home-faq-accordion-wrap'>
                        <CustomAccordion items={activeFaqs?.slice(0, 5) || []} isHome />
                        <Box className='flex'>
                            <NextLink href="/faq" className='btn dark-blue-btn mx-auto'>View all</NextLink>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};